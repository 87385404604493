import { useState, useEffect } from "react";
import { FaQuoteRight } from "react-icons/fa";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import data from "../../../../utils/SliderData";
import "./sliderBlog.css";

export default function SliderBlog() {
  const [people, setPeople] = useState(data);
  const [index, setIndex] = useState(0);

  useEffect(() => {
    const lastIndex = people.length - 1;
    if (index < 0) {
      setIndex(lastIndex);
    }
    if (index > lastIndex) {
      setIndex(0);
    }
  }, [index, people]);

  useEffect(() => {
    let slider = setInterval(() => {
      setIndex(index + 1);
    }, 3000);
    return () => {
      clearInterval(slider);
    };
  }, [index]);

  return (
    <section className="section">
      <div className="title">
        <h2>
          <span>
            <IoIosArrowBack />
          </span>
          reviews
          <span>
            <IoIosArrowForward />
          </span>
        </h2>
      </div>
      <div className="section-center">
        {people.map((person, personIndex) => {
          const { id, image, name, title, quote } = person;
          let position = "nextSlide";
          if (personIndex === index) {
            position = "activeSlide";
          }
          if (
            personIndex === index - 1 ||
            (index === 0 && personIndex === people.length - 1)
          ) {
            position = "lastSlide";
          }
          return (
            <article className={position} key={id}>
              <img src={image} alt={name} className="person-img" />
              <h3>{name}</h3>
              <p className="title">{title}</p>
              <div className="icon_Div">
                <FaQuoteRight className="icon" />
              </div>
              <p className="text">{quote}</p>
            </article>
          );
        })}
      </div>
      <div className="sliderContent">
        <h3>
          Why join a <span>coder team?</span>
        </h3>
        <div className="sliderAbout">
          <div className="slider-left">
            <img
              src="https://www.gigindia.in/assets/HomePage/dots_map.svg"
              alt="map"
            />
          </div>
          <div className="slider-right">
            <div className="slider_text_content">
              <div className="topContent">
                <div className="text">
                  <h3>50%</h3>
                  <p>Batter Learn</p>
                </div>
                <hr className="dividerVertical" />
                <div className="text">
                  <h3>ZERO</h3>
                  <p>No Cost</p>
                </div>
              </div>
              <hr className="dividerHorizontal" />
              <div className="topContent">
                <div className="text">
                  <h3>10*</h3>
                  <p>Faster Grow</p>
                </div>
                <hr className="dividerVertical" />
                <div className="text">
                  <h3>100%</h3>
                  <p>Different Learn</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
