export const backendData = [
  {
    id: 1,
    img: "url(https://storage.googleapis.com/chydlx/codepen/blog-cards/image-1.jpg)",
    date: "Aug. 01, 2023",
    urlLink: "/",
    title: "React Js",
    subTitle: "OPENING A DOOR TO THE FUTURE",
    description:
      "React makes it painless to create interactive UIs. Design simple views for each state in your application, and React will efficiently update and render just the right components when your data changes.",
  },
  {
    id: 2,
    img: "url(https://storage.googleapis.com/chydlx/codepen/blog-cards/image-1.jpg)",
    date: "Aug. 02, 2023",
    urlLink: "/",
    title: "Vue Js",
    subTitle: "OPENING A DOOR TO THE FUTURE",
    description:
      "React makes it painless to create interactive UIs. Design simple views for each state in your application, and React will efficiently update and render just the right components when your data changes.",
  },
  {
    id: 3,
    img: "url(https://storage.googleapis.com/chydlx/codepen/blog-cards/image-1.jpg)",
    date: "Aug. 03, 2023",
    urlLink: "/",
    title: "angular Js",
    subTitle: "OPENING A DOOR TO THE FUTURE",
    description:
      "React makes it painless to create interactive UIs. Design simple views for each state in your application, and React will efficiently update and render just the right components when your data changes.",
  },
  {
    id: 4,
    img: "url(https://storage.googleapis.com/chydlx/codepen/blog-cards/image-1.jpg)",
    date: "Aug. 04, 2023",
    urlLink: "/",
    title: "JQuery",
    subTitle:
      "jQuery means 'write less do more'. jQuery simplifies AJAX call and DOM manipulation.",
    description:
      "React makes it painless to create interactive UIs. Design simple views for each state in your application, and React will efficiently update and render just the right components when your data changes.",
  },
];
