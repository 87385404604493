import React from "react";
import { Col, Row } from "antd";
import { TiSocialFacebook } from "react-icons/ti";
import { RxTwitterLogo } from "react-icons/rx";
import { Input, Space } from "antd";
import { BsWhatsapp, BsInstagram } from "react-icons/bs";
import { RiMailSendFill } from "react-icons/ri";
import { Link } from "react-router-dom";
const { Search } = Input;

require("./footer.css");

const Footer = () => {
  const submit = () => {};
  return (
    <div className="main_footer">
      <Row className="footer_rowOne" justify="space-around">
        <Col
          className="first_col"
          xs={{ span: 22 }}
          md={{ span: 11 }}
          lg={{ span: 7 }}
          xl={{ span: 7 }}
        >
          <h3 className="logo">Coder Team</h3>
          <p className="footer_containt">
            We make programming simple & easy to understand. Confused on which
            course to take? I have got you covered. Browse courses and find out
            the best course for you. Its free! Code Team is my attempt to teach
            basics and those coding techniques to people in short time which
            took me ages to learn.
          </p>
        </Col>

        <Col
          className="second_col"
          xs={{ span: 22 }}
          md={{ span: 11 }}
          lg={{ span: 4 }}
          xl={{ span: 4 }}
        >
          <h4>Office</h4>
          <ul className="address">
            <li>ABC Road Whitefield, Country</li>
            <li>State, PIN 560066 </li>
          </ul>
          <ul className="ad_email">
            <u>avinashdm@outlook.com</u>
          </ul>
          <ul className="ad_phone">+91 - 0123456789</ul>
        </Col>

        <Col
          className="third_col"
          xs={{ span: 22 }}
          md={{ span: 11 }}
          lg={{ span: 4 }}
          xl={{ span: 4 }}
        >
          <h4>Links</h4>
          <ul className="links">
            <li>
              <Link to={"/"} className="link-tab">
                Home
              </Link>
            </li>
            <li>
              <Link to={"/"} className="link-tab">
                About
              </Link>
            </li>
            <li>
              <Link to={"/"} className="link-tab">
                Services
              </Link>
            </li>
            <li>
              <Link to={"/"} className="link-tab">
                Products
              </Link>
            </li>
            <li>
              <Link to={"/"} className="link-tab">
                Contact
              </Link>
            </li>
          </ul>
        </Col>

        <Col
          className="fourth_col"
          xs={{ span: 22 }}
          md={{ span: 11 }}
          lg={{ span: 7 }}
          xl={{ span: 7 }}
        >
          <h4>Newsletter</h4>
          <ul className="Newsletter">
            <li>
              <Space direction="vertical">
                <Search
                  placeholder="Your Email Address..."
                  allowClear
                  enterButton={
                    <span className="submit_btn">
                      <RiMailSendFill />
                    </span>
                  }
                  size="large"
                  onSearch={submit}
                />
              </Space>
            </li>
            <div className="footer_icon">
              <span>
                <TiSocialFacebook className="icon-link" />
              </span>
              <span>
                <BsWhatsapp className="icon-link" />
              </span>
              <span>
                <RxTwitterLogo className="icon-link" />
              </span>
              <span>
                <BsInstagram className="icon-link" />
              </span>
            </div>
          </ul>
        </Col>
      </Row>

      <hr style={{ width: "95%" }} />

      <Row className="footer_rowTwo">
        Copy &copy; {new Date().getFullYear()} - CODER TEAM
      </Row>
    </div>
  );
};

export default Footer;
