import React from "react";
import "./home.css";
import { Typewriter } from "react-simple-typewriter";
import { learnText } from "../../../../utils/header_data";

const Home = () => {
  return (
    <>
      <section className="home_section">
        <div className="home_container">
          <div className="home_content">
            <h1>
              Welcome to <span>codeTeam</span>
            </h1>
            <h3>
              Want To Learn
              <span>
                <Typewriter
                  words={learnText}
                  loop="10"
                  cursor
                  cursorStyle="_"
                  typeSpeed={70}
                  deleteSpeed={50}
                />
              </span>
            </h3>
            <p>
              We make programming simple & easy to understand. Confused on which
              course to take? I have got you covered. Browse courses and find
              out the best course for you. Its free! Code Team is my
              attempt to teach basics and those coding techniques to people in
              short time which took me ages to learn.
            </p>
            <button>Free course</button>
            <button className="btn">Free WebSite</button>
          </div>
        </div>
      </section>
    </>
  );
};

export default Home;
