export const supportedData = [
  {
    id: "1",
    name: "Admin 1",
    title: "CEO & Founder Partner",
    url: "https://img.freepik.com/free-icon/man_318-487775.jpg?size=338&ext=jpg&uid=R91834943&ga=GA1.1.1623424204.1672113266&semt=ais",
  },
  {
    id: "2",
    name: "Admin 2",
    title: "CEO & Founding Partner Beyond Next Ventures",
    url: "https://img.freepik.com/free-icon/hipster_318-487717.jpg?size=338&ext=jpg&uid=R91834943&ga=GA1.2.1623424204.1672113266&semt=ais",
  },
  {
    id: "3",
    name: "Admin 3",
    title: "Former CEO Services",
    url: "https://img.freepik.com/free-icon/photographer_318-283540.jpg?size=338&ext=jpg&uid=R91834943&ga=GA1.2.1623424204.1672113266&semt=ais",
  },
  {
    id: "4",
    name: "Admin 4",
    title: "Founder & General Partner Incubate Fund India",
    url: "https://img.freepik.com/free-icon/user_318-219676.jpg?size=338&ext=jpg&uid=R91834943&ga=GA1.1.1623424204.1672113266&semt=ais",
  },
];

export const supportedDataImage = [
  {
    id: "1",
    url: "https://img.freepik.com/free-vector/hand-drawn-business-communication-concept_23-2149167947.jpg?size=626&ext=jpg&uid=R91834943&ga=GA1.2.1623424204.1672113266&semt=ais",
  },
  {
    id: "2",
    url: "https://img.freepik.com/free-photo/toy-bricks_144627-6940.jpg?size=626&ext=jpg&uid=R91834943&ga=GA1.1.1623424204.1672113266&semt=ais",
  },
  {
    id: "3",
    url: "https://img.freepik.com/free-vector/hand-drawn-business-strategy-concept_23-2149167949.jpg?size=626&ext=jpg&uid=R91834943&ga=GA1.1.1623424204.1672113266&semt=ais",
  },
  {
    id: "4",
    url: "https://img.freepik.com/free-vector/recruiting-professionals-studying-candidate-profiles_1262-21404.jpg?size=626&ext=jpg&uid=R91834943&ga=GA1.2.1623424204.1672113266&semt=ais",
  },
  {
    id: "5",
    url: "https://img.freepik.com/free-vector/recruitment-agency-searching-job-applicants_1262-19873.jpg?size=626&ext=jpg&uid=R91834943&ga=GA1.2.1623424204.1672113266&semt=ais",
  },
];
