import React from "react";
import { about_Data } from "../../../../utils/header_data";
import "./about.css";
import img1 from "../../../../utils/images/codeTesting.jpg";
import img2 from "../../../../utils/images/api-coder.jpg";

const About = () => {
  return (
    <section className="about-section">
      {/* First About Section */}
      <div className="about-container">
        <div className="aboutLeft">
          <div className="content leftContent">
            <h4>
              <span className="title-name">Sort About</span> Us
            </h4>
            <div className="content_desc">
              <h2 className="heading-text">
                We are Genius Course work since 1980.
              </h2>
              <div className="about-description">
                <p>
                  We take our mission of increasing global access to quality
                  education seriously. We connect learners to the best
                  universities and institutions from around the world.
                </p>
              </div>
            </div>
            <div className="aboutList">
              <ul>
                {about_Data.map((list,i) => {
                  return (
                    <li key={i}>
                      <span>{list.icon}</span>
                      {list.dec}
                    </li>
                  );
                })}
              </ul>
              <div className="content_desc">
                <button className="link-btn">View All course</button>
              </div>
            </div>
          </div>
        </div>
        <div className="aboutRight">
          <div className="image-content">
            <div className="img1">
              <img src={img1} alt="codding" />
            </div>
          </div>
        </div>
      </div>
      {/* Second About Section */}
      <div className="about-container">
        <div className="aboutRight">
          <div className="image-content">
            <div className="img1">
              <img src={img2} alt="codding" />
            </div>
          </div>
        </div>
        <div className="aboutLeft">
          <div className="content">
            <h4>
              About <span className="title-name">Coder Team</span>
            </h4>
            <div className="content_desc">
              <h2 className="heading-text">
                Welcome To The Online Learning Center
              </h2>
              <div className="about-description">
                <p>
                  Just the thought of learning to code can be very intimidating.
                  The word code is mysterious by definition. It implies a
                  technical form of communication that computers, and not
                  humans, are meant to understand. One way many people start
                  learning to code is by picking a popular programming language
                  and jumping in head first with no direction. This could take
                  the form of an online coding course, a tutorial project, or a
                  random book purchase on a specific topic.
                </p>
              </div>
            </div>
            <div className="aboutList">
              <ul>
                {about_Data.map((list,i) => {
                  return (
                    <li key={i}>
                      <span>{list.icon}</span>
                      {list.dec}
                    </li>
                  );
                })}
              </ul>
              <div className="content_desc">
                <button className="link-btn">ConTACT Us</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
