import React, { useState } from "react";
import "./navbar.css";
import { AiOutlineMenu, AiOutlineClose } from "react-icons/ai";
import { menuItems } from "../../../utils/header_data";
import { TbUserCircle } from "react-icons/tb";
import { Link } from "react-router-dom";

const NavbarTop = () => {
  const [activeMenu, setActiveMenu] = useState(false);
  const menu = () => {
    setActiveMenu(!activeMenu);
  };
  return (
    <section id="top_navbar">
      <div className="navbar_container">
        <div className="navbar_logo"><span>C</span>oder <span>T</span>eam</div>
        <div className="navbar_tabs">
          <div className="nav">
            <div>
              {activeMenu ? (
                <AiOutlineClose className="humbugger_menu" onClick={menu} />
              ) : (
                <AiOutlineMenu className="humbugger_menu" onClick={menu} />
              )}
            </div>
            <div className="user_setting">
              <TbUserCircle />
            </div>
          </div>
          <ul className={activeMenu ? "nav-menu active" : "nav-menu"}>
            {menuItems.map((item) => {
              return (
                <li key={item.title} onClick={menu}>
                  <Link to={item.path} className={item.cName}>
                    {item.title}
                  </Link>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </section>
  );
};

export default NavbarTop;
