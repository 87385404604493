import React from "react";
import "./frontendDev.css";
import { Row, Col } from "antd";
import CardCourses from "../../../../utils/page/cards/index";
import { frontendData } from "../../../../utils/coursesData/developerData/frontendDeveloper";

const FrontendDev = () => {
  return (
    <Row className="frontend_container">
      {frontendData.map((frontendList) => {
        return (
          <Col
            key={frontendList.id}
            sm={{ span: 22 }}
            lg={{ span: 12 }}
            xl={{ span: 9 }}
          >
            <CardCourses
              img={frontendList.img}
              date={frontendList.date}
              urlLink={frontendList.urlLink}
              title={frontendList.title}
              subTitle={frontendList.subTitle}
              description={frontendList.description}
            />
          </Col>
        );
      })}
    </Row>
  );
};

export default FrontendDev;
