import React from "react";
import SliderBlog from "./blog/sliderBlog";
import Home from "./home/home";
import UserCounter from "./userCounter/userCounter";
import About from "./about/about";

const index = () => {
  return (
    <>
      <Home />
      <About />
      <UserCounter />
      <SliderBlog />
    </>
  );
};

export default index;
