import React from "react";
import { Route, Routes as Routers } from "react-router-dom";
import Dashboard from "./dashboard/index";
import AboutS from "./aboutUsPage";
import Services from "./services/index";
import AllCourses from "./courses/index";

const Routes = () => {
  return (
    <Routers>
      <Route path="/" element={<Dashboard />} />
      <Route path="/about" element={<AboutS />} />
      <Route path="/services" element={<Services />} />
      <Route exact path="/course" element={<AllCourses />}>
        <Route path="frontend-developer/:id" element={""} />
        <Route path="backend-developer/:id" element={""} />
        <Route path="mobile-Developer/:id" element={""} />
        <Route path="software-testing/:id" element={""} />
        <Route path="artificial-intelligence/:id" element={""} />
        <Route path="data-base/:id" element={""} />
        <Route path="cloud-computer/:id" element={""} />
        <Route path="block-chain/:id" element={""} />
        <Route path="digital-marketing/:id" element={""} />
        <Route path="data-science/:id" element={""} />
        <Route path="cyber-security/:id" element={""} />
        <Route path="ui-ux/:id" element={""} />
        <Route path="microsoft-technologies/:id" element={""} />
        <Route path="networking/:id" element={""} />
        <Route path="project-management/:id" element={""} />
        <Route path="domain/:id" element={""} />
        <Route path="tutorial/:id" element={""} />
        <Route path="course-books/:id" element={""} />
        <Route path="robotic-process/:id" element={""} />
        <Route path="operating-systems/:id" element={""} />
      </Route>
    </Routers>
  );
};

export default Routes;
