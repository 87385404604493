import React from "react";
import "./about.css";
import { Col, Row, Avatar, Image } from "antd";
import { supportedData, supportedDataImage } from "../../../../utils/aboutData";

const About = () => {
  return (
    <section id="sectionAboutUs">
      <div className="container">
        <div className="aboutUs_content">
          <h3>
            This is <span>our story</span>
          </h3>
          <p>
            There are countless online education marketplaces on the internet.
            And there’s us. We are not the biggest. We are not the cheapest. But
            we are the fastest growing. We have the highest course completion
            rate in the industry.And the reason is, we don’t give up. When a
            student commits to a course, we commit to the student. We are not
            going to sugarcoat it - we will make you complete, come what may.
            You may not have given much thought to what you are signing up for,
            but we believe you are signing up for knowledge that is useful and
            complete. And what we do, we do make that belief a reality. We are
            ridiculously committed to our students.
          </p>
          <div className="title">"CODERTEAM is biased towards growth"</div>
          <p>
            To become the largest online learning ecosystem for continuing
            education, in partnership with corporates and academia.
          </p>
          <p>
            To create an alternate platform for students who wish to continue
            and complete courses by attending live online courses, using a team
            of ridiculously committed educators who will stop at nothing to
            impart education, helped by a 24 x 7 support system. By deploying
            our world-class team of industry experts, we aim to educate our
            learners with the skills they need to advance their professional
            life to the next level.
          </p>
        </div>
      </div>
      <div className="about_Card_container">
        <h2>
          Supported By <span>Investors & Mentors</span>
        </h2>
        <Row justify="center" gutter={[10, 30]}>
          {supportedData.map((list) => {
            return (
              <Col
                className="gutter-row"
                xl={{ span: 6 }}
                sm={{ span: 12 }}
                key={list.id}
              >
                <div className="investor_about">
                  <Avatar className="avatar-images" size={100} src={list.url} />
                  <h4>{list.name}</h4>
                  <p>{list.title}</p>
                </div>
              </Col>
            );
          })}
        </Row>
      </div>

      <div className="about_Card_container">
        <h2>
          Team <span>Coder</span>
        </h2>
        <Row justify="center" gutter={[10, 30]}>
          {supportedDataImage.map((list) => {
            return (
              <Col
                className="gutter-row"
                xxl={{ span: 6 }}
                md={{ span: 12 }}
                key={list.id}
              >
                <div className="investor_about">
                  <Image
                    className="team-Images"
                    src={list.url}
                    alt="teamImages"
                    width={300}
                    height={250}
                  />
                </div>
              </Col>
            );
          })}
        </Row>
      </div>
    </section>
  );
};

export default About;
