import React from 'react'
import ServiceHeader from './serviceHeader/ServiceHeader'

const index = () => {
  return (
    <>
      <ServiceHeader />
    </>
  )
}

export default index