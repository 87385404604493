import React from "react";
import NavbarTop from "./component/header/NavbarTop";

const App = () => {
  return (
    <>
      <NavbarTop />
    </>
  );
};

export default App;
