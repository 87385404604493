import React from "react";
import "./style.css";

const index = (props) => {
  const { img, date, urlLink, title, subTitle, description } = props;
  return (
    <div className="blog-card">
      <div className="meta">
        <div
          className="photo"
          style={{
            backgroundImage: `${img}`,
          }}
        ></div>
        <ul className="details">
          <li className="author">
            <a href="#" target="_blank">
              {title}
            </a>
          </li>
          <li className="date">{date}</li>
          <li className="tags">
            <ul>
              <li>
                <a href={urlLink}>Learn {title}</a>
              </li>
            </ul>
          </li>
        </ul>
      </div>
      <div className="description">
        <h1>{title}</h1>
        <h2>{subTitle}</h2>
        <p>{description}</p>
        <p className="read-more">
          <a href={urlLink} target="_blank">Read More</a>
        </p>
      </div>
    </div>
  );
};

export default index;
