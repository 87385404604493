import { Tabs } from "antd";
import { Outlet, useParams } from "react-router-dom";
import { FrontendDev, BackendDev } from "./reactCourse/index";

const items = [
  { key: "1", label: "Frontend Developer", children: <FrontendDev /> },
  { key: "2", label: "Backend Developer", children: <BackendDev /> },
  { key: "3", label: "Mobile Developer", children: "03" },
  { key: "4", label: "Software Testing", children: "04" },
  { key: "5", label: "Artificial Intelligence", children: "05" },
  { key: "6", label: "Database", children: "06" },
  { key: "7", label: "cloud Computer", children: "07" },
  { key: "8", label: "Blockchain", children: "08" },
  { key: "9", label: "Digital Marketing", children: "09" },
  { key: "10", label: "Data science", children: "10" },
  { key: "11", label: "Cyber Security", children: "11" },
  { key: "12", label: "UI / UX", children: "12" },
  { key: "13", label: "Microsoft Technologies", children: "13" },
  { key: "14", label: "Networking", children: "14" },
  { key: "15", label: "Project management", children: "15" },
  { key: "16", label: "Domain", children: "16" },
  { key: "17", label: "Tutorial", children: "17" },
  { key: "18", label: "Course Books", children: "18" },
  { key: "19", label: "Robotic Process", children: "19" },
  { key: "20", label: "Operating systems", children: "20" },
];

const Index = () => {
  const routeParams = useParams();

  return (
    <>
      <Tabs items={items} defaultActiveKey={routeParams.id} />
      <Outlet />
    </>
  );
};

export default Index;
