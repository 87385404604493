import frontend from "../utils/images/services-img/frontend_developer.jpg";
import backend from "../utils/images/services-img/backend.jpg";
import mobile from "../utils/images/services-img/mobile.jpg";
import software from "../utils/images/services-img/software.jpg";
import ai from "../utils/images/services-img/ai.jpg";
import database from "../utils/images/services-img/database.jpg";
import cloud_developer from "../utils/images/services-img/cloud_developer.jpg";
import block_chain from "../utils/images/services-img/block_chain.jpg";
import digital_marketing from "../utils/images/services-img/digital_marketing.jpg";
import data_science from "../utils/images/services-img/data_sciences.jpg";
import cyber_security from "../utils/images/services-img/cyber_security.jpg";
import ui_ux from "../utils/images/services-img/ui_ux.jpg";
import microsoft from "../utils/images/services-img/microsoft.png";
import networking from "../utils/images/services-img/networking.jpg";
import project_management from "../utils/images/services-img/project_management.jpg";
import domain from "../utils/images/services-img/domain.jpg";
import tutorial from "../utils/images/services-img/tutorial.jpg";
import course_book from "../utils/images/services-img/course_book.jpg";
import robotic_process from "../utils/images/services-img/robotic_process.jpg";
import operating_systems from "../utils/images/services-img/operating_systems.jpg";

export const servicesDataCard = [
  {
    id: "1",
    img: frontend,
    name: "Frontend Developer",
    des: "A Front-End Developer is responsible for developing new user-facing features, determining the structure and design of web pages, building reusable codes, optimizing page loading times, and using a variety of markup languages to create the web pages.",
    url: "/course/frontend-developer",
  },
  {
    id: "2",
    img: backend,
    name: "Backend Developer",
    des: "Back end developers are responsible for creating and maintaining technology at the back end of a website (the server, database and application). The attractive visuals created by designers, UX professionals and front end developers couldn’t exist without the technology provided by a back end developer.",
    url: "/course/backend-developer",
  },
  {
    id: "3",
    img: mobile,
    name: "Mobile Developer",
    des: "A mobile app developer uses programming languages and development skills to create, test, and develop applications on mobile devices. They work in popular operating system environments like iOS and Android and often take into account UI and UX principles when creating applications.",
    url: "/course/mobile-Developer",
  },
  {
    id: "4",
    img: software,
    name: "Software Testing",
    des: "As a software tester, you'll be involved in the quality assurance stage of software development and deployment. You'll conduct automated and manual tests to ensure the software created by developers is fit for purpose and any bugs or issues are removed within a product before it gets deployed to everyday users.",
    url: "/course/software-testing",
  },
  {
    id: "5",
    img: ai,
    name: "Artificial Intelligence",
    des: "“Artificial intelligence developers build AI functionality into software applications,” according to The Enterprisers Project. “The role is generally focused on integrating and implementing AI algorithms and logic into the deliverables of an IT project.”",
    url: "/course/artificial-intelligence",
  },
  {
    id: "6",
    img: database,
    name: "Database",
    des: "What Does a Database Developer Do? Database developers use computer software to organize and save data. Their key responsibilities include determining user's needs, ensuring that information is secure, monitoring database performance, and providing support.",
    url: "/course/data-base",
  },
  {
    id: "7",
    img: cloud_developer,
    name: "cloud Computer",
    des: "What is a Cloud Developer? In general, developers are professionals who build and design useful websites, programs, applications, etc. either from the start or with the help of templates and predefined resources. A professional Cloud Developer is one who builds computer applications and software.",
    url: "/course/cloud-computer",
  },
  {
    id: "8",
    img: block_chain,
    name: "Blockchain",
    des: "Blockchain developers enable secure digital transactions by creating systems to record and store blockchain data in a way that prevents changes or hacks. They design secure blockchain technologies, develop application features and interfaces, and maintain client and server-side applications.",
    url: "/course/block-chain",
  },
  {
    id: "9",
    img: digital_marketing,
    name: "Digital Marketing",
    des: "The objective of digital marketing is to develop strong and innovative strategies to promote the business brand, products, and services. A digital marketing professional is expected to effectively use all marketing tools and techniques like PPC, SEO, SEM, email, social media, and display advertising.",
    url: "/course/digital-marketing",
  },
  {
    id: "10",
    img: data_science,
    name: "Data science",
    des: "A data scientist is someone who makes value out of data. Such a person proactively fetches information from various sources and analyzes it for better understanding about how the business performs, and builds AI tools that automate certain processes within the company.",
    url: "/course/data-science",
  },
  {
    id: "11",
    img: cyber_security,
    name: "Cyber Security",
    des: "Security software developers create new security technologies and make changes to existing applications and programs. They may also integrate security protocols into existing software applications and programs. These professionals often participate in the entire lifecycle of a software program.",
    url: "/course/cyber-security",
  },
  {
    id: "12",
    img: ui_ux,
    name: "UI / UX",
    des: "UI UX designers create the user interface for an app, website, or other interactive media. Their work includes collaborating with product managers and engineers to gather requirements from users before designing ideas that can be communicated using storyboards. They also process flows or sitemaps.",
    url: "/course/ui-ux",
  },
  {
    id: "13",
    img: microsoft,
    name: "Microsoft Technologies",
    des: "The main responsibility of Microsoft Certified Professional Developers is to help organizations solve problems related to Microsoft technologies. They must know how to use development tools such as Visual Studio, SharePoint and . NET Framework. They should develop and deploy Microsoft technologies.",
    url: "/course/microsoft-technologies",
  },
  {
    id: "14",
    img: networking,
    name: "Networking",
    des: "A network developer is responsible for building, developing, and maintaining data networks. They implement computer information networks, optimize existing systems, and troubleshoot issues, ensuring data moves effectively between data centers.",
    url: "/course/networking",
  },
  {
    id: "15",
    img: project_management,
    name: "Project management",
    des: "A project developer is a professional who manages the operations of a project by conducting research and creating plans that best suit the needs of the project. These professionals usually work alongside the project manager to develop project activities, schedules and regular project progress reports.",
    url: "/course/project-management",
  },
  {
    id: "16",
    img: domain,
    name: "Domain",
    des: "Domain Engineering (DE) is the process of analyzing software systems for the concepts, notations and implementation methods, and codifying that knowledge in a reusable form (in this, packaging that knowledge for use by DMS).",
    url: "/course/domain",
  },
  {
    id: "17",
    img: tutorial,
    name: "Tutorial",
    des: "A tutorial, in education, is a method of transferring knowledge and may be used as a part of a learning process. More interactive and specific than a book or a lecture, a tutorial seeks to teach by example and supply the information to complete a certain task.",
    url: "/course/tutorial",
  },
  {
    id: "18",
    img: course_book,
    name: "Course Books",
    des: "A textbook, a book designed to accompany a specific academic course, or one specified by the writers of the course to be read by its students.",
    url: "/course/course-books",
  },
  {
    id: "19",
    img: robotic_process,
    name: "Robotic Process",
    des: "Robotic process automation (RPA) is a software technology that makes it easy to build, deploy, and manage software robots that emulate humans actions interacting with digital systems and software.",
    url: "/course/robotic-process",
  },
  {
    id: "20",
    img: operating_systems,
    name: "Operating systems",
    des: "An operating system (OS) is the program that, after being initially loaded into the computer by a boot program, manages all of the other application programs in a computer. The application programs make use of the operating system by making requests for services through a defined application program interface (API).",
    url: "/course/operating-systems",
  },
];
