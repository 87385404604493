import { MdGroups } from "react-icons/md";
import { FaHandHoldingHeart } from "react-icons/fa";
import { BsFillBarChartFill } from "react-icons/bs";
import { MdDoubleArrow } from "react-icons/md";

export const menuItems = [
  {
    title: "Home",
    path: "/",
    cName: "nav-links",
  },
  {
    title: "Course",
    path: "/course",
    cName: "nav-links",
  },
  {
    title: "Services",
    path: "/services",
    cName: "nav-links",
  },
  {
    title: "About",
    path: "/about",
    cName: "nav-links",
  },
];

export const learnText = [
  "C pogroming",
  "java",
  "JavaScript",
  "Web Development",
  "react js",
  "Angular",
  "jQuery",
  "nodejs",
  "python",
  "php",
  "Ruby",
  "SQL",
  "DSA",
  "data science",
  "mysql",
  "Data Analytics",
  "Ui/ux design",
];

export const userCountLerna = [
  { id: 1, name: "HAPPY LEARNERS", number: "300000", icon: <MdGroups /> },
  {
    id: 2,
    name: "MONTHLY VIEWS",
    number: "4",
    icon: <FaHandHoldingHeart />,
  },
  {
    id: 3,
    name: "new month visitors",
    number: "200000",
    icon: <BsFillBarChartFill />,
  },
];

export const about_Data = [
  {
    icon: <MdDoubleArrow className="arrowIcon" />,
    dec: "Get unlimited access to 22000+ of our top courses",
    decS: "Professional And Experienced Since 1980",
  },
  {
    icon: <MdDoubleArrow className="arrowIcon" />,
    dec: "Explore a variety of fresh educational topics",
    decS: "We Connect Learners To The Best Universities From Around The World",
  },
  {
    icon: <MdDoubleArrow className="arrowIcon" />,
    dec: "Find the best qualified teacher for you",
    decS: "Our Mission Increasing Global Access To Quality Adulation",
  },
];


