import React from "react";
import "./userCounter.css";
import { userCountLerna } from "../../../../utils/header_data";
import CountUp from "react-countup";

const UserCounter = () => {
  return (
    <section className="userCounter_Section">
      <div className="user_Counter_Main">
        <div className="userCounter_content">
          <div className="user_Heading">Most Popular Coding Team</div>
        </div>
        <div className="userCount_Container">
          {userCountLerna.map((userCount) => {
            return (
              <div className="user_learners" key={userCount.id}>
                <div className="user_text_number">
                  <div className="user_icon">{userCount.icon}</div>
                  <span>
                    <CountUp end={userCount.number} />
                  </span>
                  <h5 className="crore_title">Crore </h5>
                  <span>+</span>
                </div>
                <div className="user_text">{userCount.name}</div>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default UserCounter;
