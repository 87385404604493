import React from "react";
import { Card, Row, Col } from "antd";
import { servicesDataCard } from "../../../../utils/servicesData";
import { Link } from "react-router-dom";
const { Meta } = Card;
require("./serviceHeader.css");

const ServiceHeader = () => {
  return (
    <>
      <section id="service-section">
        <div className="service_main">
          <div className="main_heading">
            <h2>
              Welcome to <span>Coder Team</span>
            </h2>
            <h4>We Provide Best Service</h4>
            <h4>
              Frontend <span>*</span> Backend <span>*</span> Design
            </h4>
          </div>
          <div className="service_header_btn">
            <button className="header_btn">Free course</button>
            <button className="header_btn">Paid Course</button>
          </div>
        </div>
        <Row className="main_row">
          {servicesDataCard.map((lists) => {
            return (
              <Col
                className="card_container"
                xs={{ span: 20 }}
                sm={{ span: 20 }}
                lg={{ span: 7 }}
                xl={{ span: 5 }}
                key={lists.id}
              >
                <Link to={`${lists.url}/${lists.id}`}>
                  <Card
                    className="card"
                    cover={
                      <img
                        alt="example"
                        src={lists.img}
                        className="card_image"
                      />
                    }
                  >
                    <div className="text-container">
                      <Meta title={lists.name} />
                      <p className="des_course">{lists.des}</p>
                    </div>
                  </Card>
                </Link>
              </Col>
            );
          })}
        </Row>
      </section>
    </>
  );
};

export default ServiceHeader;
